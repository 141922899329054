<template>
  <div>
    <el-card shadow="never" style="border: 0" class="mt20">
      <div slot="header" class="clearfix">
        <span>分销商品排名</span>
        <el-input
          v-model="goodsName"
          placeholder="请输入商品名称"
          class="w220 ml20 mr20"
        />
        <el-button @click="goodRank">搜索</el-button>
      </div>
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column
            align="center"
            type="index"
            label="排名"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            prop="goodsName"
            label="商品名称"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            prop="sharesNum"
            label="分享次数"
            show-overflow-tooltip
          />
          <el-table-column
            align="center"
            prop="salesNum"
            label="购买次数"
            show-overflow-tooltip
          />
          <el-table-column align="center" label="客单价" show-overflow-tooltip>
            <template slot-scope="scope">
              {{
                scope.row.salesNum == 0
                  ? 0
                  : (scope.row.price / 100 / scope.row.salesNum).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="price" label="总销售额">
            <template slot-scope="scope">
              {{
                scope.row.price == 0 ? 0 : (scope.row.price / 100).toFixed(2)
              }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="commissionAmount"
            label="累计支出佣金"
          >
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
  </div>
</template>

<script>
import { goodRank } from "@/api/distribution";
import commonTable from "@/components/common/commonTable";
export default {
  data() {
    return {
      goodsName: "",
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      pageSize2: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
    };
  },
  components: {
    commonTable,
  },
  created(){
    this.goodRank()
  },
  methods: {
    /**@method 查询分销商品 */
    async goodRank() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        goodsName: this.goodsName,
      };
      try {
        this.loading = true;
        const result = await goodRank(data);
        this.loading = false;
        const {
          pageInfo: { total, list },
        } = result?.data;
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.goodRank();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.goodRank();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>