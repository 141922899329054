<template>
  <div>
    <div class="view pa24">
      <el-row>
        <el-col :span="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">￥{{ sumBalance }}</h2>
            <span>累计发放佣金</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">￥{{ sumWithdrawBalance }}</h2>
            <span>已提现金额</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">{{ orderNum }}个</h2>
            <span>待收货订单</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="d-flex flex-column align-items-center">
            <h2 style="color: #fd634e">￥{{ commissionAmount }}</h2>
            <span>待到账金额</span>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-col :span="12">
      <el-card shadow="never" style="border: 0" class="mt20">
        <div class="d-flex align-items-center">
          <span style="color: #51cbcd">佣金支出统计</span>
          <el-date-picker
            v-model="effectiveDate"
            class="ml20"
            align="right"
            type="daterange"
            value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
          <el-button class="ml10" type="primary" @click="expenditureCount">搜索</el-button>
        </div>
        <div  class="pt20 bgfff" id="container"></div>
      </el-card>
    </el-col>
    <el-col :span="12">
      <el-card shadow="never" style="border: 0" class="mt20">
        <div class="d-flex align-items-center">
          <span style="color: #51cbcd">分销商品占比</span>
        </div>
        <div id="roomTypeCheckIn"></div>
      </el-card>
    </el-col>
    <el-col :span="24">
      <distributionGoods />
    </el-col>
    <el-col :span="24" style="padding-bottom: 24px">
      <distributionUsers />
    </el-col>
  </div>
</template>

<script>
import { Chart } from "@antv/g2";
import {
  commissionCount,
  goodRankPercentage,
  expenditureCount,
} from "@/api/distribution";
import distributionUsers from "./components/distributionUsers";
import distributionGoods from "./components/distributionGoods";
export default {
  data() {
    return {
      sumBalance: 0,
      sumWithdrawBalance: 0,
      orderNum: 0,
      commissionAmount: 0,
      effectiveDate: [],
      data: [{ type: "销售额", value: 0 }],
      mydata: [],
      mychart:''
    };
  },
  components: {
    distributionUsers,
    distributionGoods,
  },
  created() {
    this.commissionCount();
    this.goodRankPercentage();
    this.expenditureCount();
    
  },
  mounted(){
    this.initComponentTow()
  },
  methods: {
    initComponent() {
      const chart = new Chart({
        container: "roomTypeCheckIn",
        autoFit: true,
        height: 410,
        width: 400,
      });
      chart.data(this.data);
      chart.legend(false);
      chart.tooltip({
        showMarkers: false,
      });
      chart.facet("rect", {
        fields: ["type"],
        padding: 20,
        showTitle: false,
        eachView: (view, facet) => {
          const data = facet.data;
          let color = "#0a9afe";
          debugger
          data.push({ type: "其他", value: 100 - data[0].value });
          view.data(data);
          view.coordinate("theta", {
            radius: 0.8,
            innerRadius: 0.5,
          });
          view
            .interval()
            .adjust("stack")
            .position("value")
            .color("type", [color, "#eceef1"])
            .style({
              opacity: 1,
            });
          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].type,
            style: {
              fontSize: 12,
              fill: "#8c8c8c",
              fontWeight: 300,
              textBaseline: "bottom",
              textAlign: "center",
            },
            offsetY: -12,
          });

          view.annotation().text({
            position: ["50%", "50%"],
            content: data[0].value,
            style: {
              fontSize: 18,
              fill: "#000",
              fontWeight: 500,
              textAlign: "center",
            },
            offsetY: 10,
          });

          view.interaction("element-active");
        },
      });
      chart.render();
    },
    initComponentTow() {
      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: 378,
        width: 400,
      });
      chart.data(this.mydata);
      chart.scale("commissionAmount", {
        alias: "销售额",
      });

      chart.axis("createdTime", {
        tickLine: {
          alignTick: false,
        },
      });
      chart.axis("commissionAmount", false);

      chart.tooltip({
        showMarkers: false,
      });
      chart
        .interval()
        .position("createdTime*commissionAmount")
        .color("createdTime");
      chart.interaction("element-active");

      // 添加文本标注
      this.mydata.forEach((item) => {
        chart
          .annotation()
          .text({
            position: [item.createdTime, item.commissionAmount],
            content: item.commissionAmount,
            style: {
              textAlign: "center",
            },
            offsetY: -30,
          })
          .text({
            position: [item.createdTime, item.commissionAmount],
            content: (item.commissionAmount * 100).toFixed(0) + "%",
            style: {
              textAlign: "center",
            },
            offsetY: -12,
          });
      });
      chart.render();
      this.mychart = chart
    },
    async commissionCount() {
      try {
        const result = await commissionCount({ xx: 0 }, { showLoading: true });
        const {
          data: { commissionAmount, orderNum, sumBalance, sumWithdrawBalance },
        } = result;
        this.sumBalance = sumBalance;
        this.sumWithdrawBalance = sumWithdrawBalance;
        this.orderNum = orderNum;
        this.commissionAmount = commissionAmount;
      } catch (error) {
        console.log(error);
      }
    },
    async goodRankPercentage() {
      try {
        const result = await goodRankPercentage({ xx: 0 });
        const {
          pageInfo: { list },
        } = result.data;
        let price = 0;
        list.forEach((item) => {
          price += item.price;
        });
        this.data[0].value = price;
        this.initComponent();
      } catch (error) {
        console.log(error);
      }
    },
    async expenditureCount() {
      let data = {
        typeTime: (!this.effectiveDate || this.effectiveDate.length == 0 ) ? 'year' : '',
        startTime: this.effectiveDate  && this.effectiveDate[0] ,
        endTime: this.effectiveDate && this.effectiveDate[1],
      };
      const result = await expenditureCount(data);
      this.mydata = result.data ? result.data : []
      this.mychart.changeData(this.mydata)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>